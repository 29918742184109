import React from "react";
import Layout from "../components/layout.component";
import { Helmet } from "react-helmet";
import { translationHelper } from "../helpers/translation.helper";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { useConfig } from "../hooks/use-config.hook";
import CampaignCodeWidget from "../widgets/campaign-code.widget";
import { useLocation } from "@reach/router";

const CodePage: React.FC = () => {
    const config = useConfig();

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const redirectUrl = urlParams.get("r") || undefined;

    return (
        <Layout>
            <Helmet>
                <title>
                    Syötä kampanjakoodi -{" "}
                    {translationHelper(config.title, "fi")}
                </title>
            </Helmet>
            <GatsbySeo noindex={true} nofollow={true} />
            <CampaignCodeWidget redirect_url={redirectUrl} showButton={true}/>
        </Layout>
    );
};

export default CodePage;
